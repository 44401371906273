import React from 'react';
import {PageView, initGA, Event} from './Tracking';
import axios from 'axios';
import {
    GoogleReCaptchaProvider,
    GoogleReCaptcha
  } from 'react-google-recaptcha-v3';

class Form extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isOpen: false,

            inputName: {valid: true, value: ''},
            inputContact: {valid: true, value: ''},
            inputCard: {valid: true, value: ''},

            formValid: false,
            formSent: false,
            formSending: false,

            stickyStyle: {}
        };

        this.toggleSticky= this.toggleSticky.bind(this);
        this.closeSticky= this.closeSticky.bind(this);

        this.handleChange = this.handleChange.bind(this);
        this.trySubmit = this.trySubmit.bind(this);

        this.setupEvents(this);
    }

    setupEvents() {
        const _this = this;

        let wW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        let wH = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        let mobileWidth = 996;

        window.onscroll = function() {
            let $footer = document.querySelectorAll('.js-footer')[0];
            let fHeight = $footer.offsetHeight;
            let maxH = $footer.offsetTop - wH;

            let scrollTop = window.scrollY;
            let obj = {};

            if(wW > mobileWidth){
                if(scrollTop >= (maxH - 20)){
                    obj = {
                        bottom: fHeight + 30
                    };
                }
                else{
                    obj = {};
                }

                _this.setState({ stickyStyle: obj });
            }
        };
    }

    toggleSticky() {
        const currentState = this.state.isOpen;
        this.setState({ isOpen: !currentState });
    }

    closeSticky() {
        this.setState({ isOpen: false });
    }

    handleChange(event) {
        const key = event.target.dataset.obj;

        this.setState(
            {
                [key]: {
                    valid: event.target.value !== '',
                    value: event.target.value
                },
            },
            this.validateForm
        );
    }

    validateForm(type) {
        let count = 0;
        let elements = document.querySelectorAll("form#leadForm input");

        for (let i = 0; i < elements.length; i++) {
            let elName = elements[i].dataset.obj;
            let elRequired = elements[i].dataset.required;

            if(elRequired && this.state[elName].value === ''){
                if(type === 'setState'){
                    this.setState({[elName]: {valid: false, value: ''}});
                }
                count++;
            }
        }

        this.setState({formValid: (count === 0)});
        return (count === 0);
    }

    pushEvents(){
        let urlParams = new URLSearchParams(window.location.search);
        let urlString = urlParams.toString();
        let source = urlParams.get('utm_source');

        if(urlString !== '' && source !== '' && window.dataLayer[2][1] !== ''){
            initGA(window.dataLayer[2][1]);
            PageView('/submissao_efectuada/'+source);
            Event('Form Submission', source, '');
        }
    }

    trySubmit() {

        if(this.validateForm('setState') && !this.state.formSending){
            this.setState({formSending: true});
            const dataObj = {
                "Subject": "LP Poupa Mais - Envio de formulário",
                "Message": "Email enviado a partir do formulário presente na landing page BP Poupa Mais \n----------------------------------------\n\nNome: " + this.state.inputName.value + "\n Contacto: " + this.state.inputContact.value + "\nNº Cartão: " + (this.state.inputCard.value === '' ? '(não fornecido)' : this.state.inputCard.value) + "\n\n----------------------------------------\nNão responda a este email.\n----------------------------------------",
                "ResponseToken": this.token};

            const formAction = document.getElementById('bpApp').dataset.formAction;
            const sendURI = (formAction !== undefined && formAction !== '' ? formAction : "/api/submit");

            // POST TO AWS LAMBDA API
            axios.post(sendURI, dataObj)
                .then(() => {
                    this.setState({
                        formSent: true,
                        formSending: false});


                    this.pushEvents();

                })
                .catch((err) => {
                    alert(`Ocorreu um erro ao validar o formulário no servidor, refresque a página e tente novamente.`);
                    this.setState({
                        formSent: false,
                        formSending: false});
                  });
        }
        else{
            this.setState({
                formSent: false,
                formValid: false,
                formSending: false
            });
        }
    }

    render() {
        return <>
            <section style={this.state.stickyStyle} className={'module module-form-sticky js-formSticky' + (this.state.isOpen ? ' is-open': '') + (this.state.formSent ? ' is-success' : '')}>
                <button className="btn-close" data-action="closeSticky" onClick={this.closeSticky}>
                    <i className="icon icon-color-gray icon-cross js-placeIcon" data-id="icon-cross">&nbsp;</i>
                </button>

                <form id="leadForm">
                    <h3 className="title-lg">
                        <span className="color-gray">{this.props.data.upperTitle}</span>
                        <span className="color-green">{this.props.data.title}</span>
                    </h3>
                    <div className={'form-group' + (!this.state.inputName.valid ? ' has-error' : '')}>
                        <label htmlFor="name">Nome</label>
                        <input type="text" name="name" id="name" placeholder="Primeiro e último nome" onChange={this.handleChange} data-obj="inputName" data-required="true" value={this.state.inputName.value} />
                        <span className="label-error">Introduza um nome válido</span>
                    </div>
                    <div className="form-columns">
                        <div className={'form-group' + (!this.state.inputContact.valid ? ' has-error' : '')}>
                            <label htmlFor="contact">Contacto</label>
                            <input type="text" name="contact" id="contact" placeholder="Nº de Telemóvel" onChange={this.handleChange} data-obj="inputContact" data-required="true" value={this.state.inputContact.value} />
                            <span className="label-error">Introduza um nº válido</span>
                        </div>
                        <div className={'form-group'}>
                            <label htmlFor="card">Cartão Poupa Mais <span>(opcional)</span></label>
                            <input type="text" name="card" id="card" placeholder="Nº do Cartão Poupa Mais" onChange={this.handleChange} data-obj="inputCard" value={this.state.inputCard.value} />
                            <span className="label-error">Introduza um nº válido</span>
                        </div>
                    </div>
                    <div className="form-copy">
                        {this.props.data.disclaimer}
                    </div>
                    <div className="form-action">
                        <button type="button" className="btn btn-green" onClick={this.trySubmit}>{this.props.data.ctaButton}</button>
                    </div>
                </form>
                <div className="form-response">
                    <div className="resp">
                        <i className="icon icon-color-white icon-tick  js-placeIcon" data-id="icon-tick">&nbsp;</i>
                        <span className="title-lg">{this.props.data.messageTitle}</span>
                        <span className="text">{this.props.data.messageText}</span>
                    </div>
                </div>
            </section>
           
            <button type="button" className="btn btn-fixed" onClick={!this.state.isOpen ? this.toggleSticky : this.trySubmit}>{this.props.data.ctaButton}</button>
            
            <GoogleReCaptchaProvider reCaptchaKey="6LeiViIqAAAAAJsKLnHoZ-6h0kC4gkg1kjoS8UKS">
                <GoogleReCaptcha onVerify={token => this.token = token} />
            </GoogleReCaptchaProvider>
        </>;
    }
}

export default Form;